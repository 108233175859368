
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  nextTick,
} from "vue";
import {
  getOverviewValue,
  getRegionCheckDetailTableData,
  getPicBasic,
} from "@/API/delayCallback/index";
import {
  DelayApplySearchType,
  StatusListCnCheck,
  ApplyListCnCheck,
} from "@/views/DelayCallback/types";
import { SelectFormat } from "@/views/Delay/type";
import downloadFile from "@/utils/claim/downloadFile";
import { useRouter } from "vue-router";
import setColumnWidth from "@/utils/rv/setColumnWidth";
import base64toBlob from "@/utils/rv/base64toBlob";
import { message } from "ant-design-vue";
import useDelay from "@/hooks/claimDelay/useDelay";
export default defineComponent({
  setup() {
    const { spaceSize } = useDelay();
    const activeKey = ref("1");
    const router = useRouter();
    const failReason = ref<string>("");
    const failReasonVisible = ref<boolean>(false);
    const applyTypeList = ref<SelectFormat[]>([
      { value: "1", title: "过期申请" },
      { value: "2", title: "过期撤回" },
    ]);
    const statusList = ref<SelectFormat[]>([
      { value: "1", title: "已提交" },
      { value: "2", title: "区域已通过" },
      { value: "3", title: "区域已拒绝" },
      { value: "4", title: "SIPO已通过" },
      { value: "5", title: "SIPO已拒绝" },
    ]);

    const tableData = ref([]);
    const tableHeight = ref();
    const columns = [
      {
        title: "申请编号",
        width: 150,
        dataIndex: "applyCode",
      },
      {
        title: "申请类别",
        width: 120,
        dataIndex: "applyType",
        slots: { customRender: "applyType" },
      },
      {
        title: "车架号",
        width: 150,
        dataIndex: "vinNo",
      },
      {
        title: "零售日期",
        width: 120,
        dataIndex: "retailDate",
      },
      {
        title: "申请日期",
        width: 120,
        dataIndex: "applyDate",
      },
      {
        title: "状态",
        width: 120,
        dataIndex: "status",
        slots: { customRender: "status" },
      },
      {
        title: "文件", //TODO 下载附件
        fixed: "right",
        width: 380,
        dataIndex: "document",
        slots: { customRender: "document" },
      },
      {
        title: "申请拒绝原因", //TODO 等UI
        fixed: "right",
        width: 120,
        dataIndex: "failReason",
        slots: { customRender: "failReason" },
      },
    ];

    //* 概览数据
    const overviewValue = reactive({
      delayRequset: "",
      delayRequsetApproved: "",
      callBackRequset: "",
      callBackRequsetApproved: "",
    });

    const seachParams = reactive<DelayApplySearchType>({
      applyCode: "",
      applyType: "",
      applyDate: "",
      status: "",
      dealerCode: "",
      dealerNameEn: "",
      vinNo: "",
      current: 0,
      size: 20,
      dealerNameCn: "",
      regionalManagerCode: "",
      regionalManagerName: "",
      retailDate: "",
    });

    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 0,
      total: 0,
    });

    const tabChange = () => {
      if (activeKey.value == "2") {
        router.push({ path: "/claimDelay/dealerClaim" });
      }
      if (activeKey.value == "3") {
        router.push({ path: "/delayUsedCar/dealer" });
      }
    };

    // 刷新表格高度
    const getTableScroll = () => {
      nextTick(() => {
        //  默认底部分页50 + 边距10
        const extraHeight = 120;
        const tHeader = document.getElementsByClassName("table-wrap")[0];
        //表格内容距离顶部的距离
        let tHeaderBottom = 0;
        if (tHeader) {
          tHeaderBottom = tHeader.getBoundingClientRect().top;
        }
        //窗体高度-表格内容顶部的高度-表格内容底部的高度
        const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
        tableHeight.value = height;
        if (!(tableData.value && tableData.value.length > 0)) {
          (
            document.getElementsByClassName("ant-table-placeholder")[0] as any
          ).style.height = `calc(100vh - ${tHeaderBottom + 80}px)`;
        }
      });
    };

    //TODO 概览数据
    const getOverview = () => {
      getOverviewValue().then((res) => {
        overviewValue.delayRequset = res.delayApplyCount;
        overviewValue.delayRequsetApproved = res.delayApplyWaitingCount;
        overviewValue.callBackRequset = res.callBackCount;
        overviewValue.callBackRequsetApproved = res.callBackWaitingCount;
      });
    };

    const reset = () => {
      seachParams.applyCode = "";
      seachParams.applyType = "";
      seachParams.applyDate = "";
      seachParams.status = "";
      seachParams.vinNo = "";
      seachParams.retailDate = "";
      seachParams.current = 1;
      seachParams.size = 20;
    };

    //TODO 表格数据
    const getTableData = () => {
      seachParams.current = pagination.currentPage;
      seachParams.size = pagination.pageSize;
      getRegionCheckDetailTableData(seachParams).then((res) => {
        if (res !== "") {
          tableData.value = res.pageList.content;
          pagination.total = parseFloat(res.pageList.totalElements);
          const customerColumn = ["applyCode", "dealerNameEn"];
          tableData.value.length &&
            Object.assign(
              columns,
              setColumnWidth(columns, tableData.value, customerColumn)
            );
        }
      });
      getTableScroll();
    };

    const quickSearch = (
      requestTypeCondition: string,
      statusCondition = ""
    ) => {
      reset();
      seachParams.applyType = requestTypeCondition;
      seachParams.status = statusCondition;
      getTableData();
    };

    // 发票/行驶证/证明 查看弹窗
    const pictureModal = reactive({
      pictureModalFlag: false,
      pictureModalTitle: "",
      pictureModalImg: "",
      fileName: "",
      pdf: "",
      base64Blob: {},
      isImage: true,
    });

    const viewPicEvent = (vinNo: string, type: string, dealerCode: string) => {
      if (type == "license") {
        pictureModal.pictureModalTitle = "RE_" + vinNo;
      } else if (type == "invoice") {
        pictureModal.pictureModalTitle = "IN_" + vinNo;
      } else if (type == "prove") {
        pictureModal.pictureModalTitle = "延期证明_" + vinNo;
      } else if (type === "relationProof") {
        pictureModal.pictureModalTitle = "RP_" + vinNo;
      }
      const params = { vinNo, type, dealerCode };
      getPicBasic({ params }).then((res: any) => {
        if (!res) {
          message.error("数据为空");
          return;
        }
        let URL = {};
        pictureModal.fileName = res.docName;
        const fileName = res.docName;
        const fileUrl = res.base64;
        if (fileName.indexOf(".docx") > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(
            fileUrl,
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          );
          pictureModal.pdf = window.URL.createObjectURL(URL);
        } else if (fileName.indexOf(".xlsx") > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(
            fileUrl,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          pictureModal.pdf = window.URL.createObjectURL(URL);
        } else if (fileName.indexOf(".jpg") > -1) {
          pictureModal.isImage = true;
          pictureModal.pictureModalImg = "data:image/jpeg;base64," + res.base64;
          URL = base64toBlob(fileUrl, "image/jpeg");
        } else if (fileName.indexOf(".pdf") > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(fileUrl, "application/pdf");
          pictureModal.pdf = window.URL.createObjectURL(URL);
        }
        pictureModal.pictureModalFlag = true;
        pictureModal.base64Blob = URL;
      });
    };
    const downloadImg = (pictureModal: any) => {
      const downloadElement = document.createElement("a");
      downloadElement.href = window.URL.createObjectURL(
        pictureModal.base64Blob
      );
      downloadElement.download = pictureModal.fileName;
      downloadElement.click();
    };
    const closePictureModal = () => {
      pictureModal.pictureModalFlag = false;
    };

    const exportExcel = () => {
      const params = {
        url: "./rvapi/region/approve/export",
        method: "post",
        params: seachParams,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };

    const submit = () => {
      router.push({
        path: "/delayCallback/overdueSearchDeclaration",
      });
    };

    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      getTableData();
    };

    const openModal = (record: any) => {
      failReason.value = record.refuseReason;
      failReasonVisible.value = true;
    };

    const closeModal = () => {
      failReasonVisible.value = false;
      failReason.value = "";
    };

    onMounted(() => {
      getOverview();
      getTableData();
    });
    return {
      failReason,
      failReasonVisible,
      tableHeight,
      status,
      statusList,
      seachParams,
      tableData,
      columns,
      reset,
      ...toRefs(overviewValue),
      ...toRefs(pagination),
      pageChange,
      sizeChange,
      exportExcel,
      submit,
      openModal,
      closeModal,
      activeKey,
      getTableScroll,
      pictureModal,
      viewPicEvent,
      downloadImg,
      closePictureModal,
      pagination,
      overviewValue,
      applyTypeList,
      getOverview,
      StatusListCnCheck,
      ApplyListCnCheck,
      tabChange,
      getTableData,
      spaceSize,
      quickSearch,
    };
  },
});
